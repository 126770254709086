import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Dsgvo from "../images/dsgvo.jpg"

const DatenschutzPage = () => (
  <Layout>
    <SEO title="Datenschutz" />

    <div
      className="site-blocks-cover inner-page overlay"
      style={{ backgroundImage: `url(${Dsgvo})` }}
    >
      <div className="row align-items-center justify-content-center mx-2">
        <div className="col-12 text-center">
          <h1 className="text-uppercase text-center">
            <span>Datenschutz</span>
          </h1>
        </div>
      </div>
    </div>
    <div className="yellow-lines"></div>

    <div className="site-section py-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="title">Datenschutzerklärung</h1>
            <p>
              Der Helmarts e.U. („Helmart's“) ist der Schutz Ihrer Daten sehr
              wichtig. Die Erfassung und Verarbeitung Ihrer personenbezogenen
              Daten erfolgt daher in vollem Einklang mit dem geltenden
              Datenschutzrecht. Im Folgenden erhalten Sie nähere Informationen
              zur Datenverarbeitung bei Helmart's:
            </p>
            <h2>1. Verantwortlicher Verarbeiter</h2>
            <p>
              Verantwortlicher für die Verarbeitung Ihrer personenbezogenen
              Daten ist
            </p>
            <p className="pl-3">
              <b>Helmarts e.U.</b>
              <br />
              Patrick Graf
              <br />
              Thürnlhofstraße 5/6/606, 1110 Wien, Österreich
              <br />
              E-Mail: <a href="mailto:office@helmarts.at">office@helmarts.at</a>
              <br />
              Telefonnummer: +43 660 69 333 05
            </p>
            <h2>2. Verarbeitungen Ihrer Daten durch Helmart's</h2>
            <h3>2.1. Datenverarbeitung bei Anfragen an Helmart's</h3>
            <h4>a. Verarbeitungszweck und Rechtsgrundlage</h4>
            <p>
              Die persönlichen Daten, die Sie Helmart's im Zuge einer Anfrage zu
              Produkten oder Leistungen oder anlässlich eines Ersuchens um
              Übermittlung eines Angebots bekannt geben, werden gespeichert und
              verarbeitet, damit Helmart's Ihre Anfrage oder Ihr Ersuchen
              entsprechend bearbeiten kann. Die Rechtsgrundlage dieser
              Datenverarbeitung bildet Art 6 Abs 1 lit b DSGVO, wonach die
              Verarbeitung personenbezogener Daten, die zur Durchführung
              vorvertraglicher Maßnahmen auf Anfrage der betroffenen Person
              erforderlich sind, ausdrücklich zulässig ist.
            </p>
            <h4>b. Speicherdauer</h4>
            <p>
              Ihre Daten werden nur so lange gespeichert, wie dies erforderlich
              ist, um Ihre Anfrage zu bearbeiten. Wenn Ihre Anfrage nicht zu
              einem Vertragsabschluss führt, löscht Helmart's Ihre Daten
              spätestens 6 Monate nach der letzten Korrespondenz zwischen
              Helmart's und Ihnen.
            </p>
            <h4>c. Empfänger personenbezogener Daten</h4>
            <p>
              Wenn Sie Ihre Anfrage an Helmart's per Online-Kontaktformular (auf{" "}
              <Link to="/kontakt/">Kontakt</Link>) senden, werden Ihre Daten
              auch den Auftragsverarbeitern offengelegt, deren sich Helmarts
              e.U. in Zusammenhang mit der Erfassung und der Bearbeitung Ihrer
              Anfrage bedient. Hierbei handelt es sich um:
            </p>
            <p className="pl-3">
              <b>Netlify, Inc.</b> mit Sitz in 2325 3rd Street, Suite 215, San
              Francisco, California 94107 (USA)
            </p>
            <h4>d. Übermittlung in Drittländer</h4>
            <p>
              Wenn Sie bei einer Anfrage an Helmart's das Online-Kontaktformular
              von www.helmarts.at nutzen, erfolgt eine Übermittlung der Daten,
              die Sie bekannt gegeben haben, an die{" "}
              <strong>Netlify, Inc</strong>, mit Sitz in USA. Die USA sind ein
              „Drittland“ im Sinne der DSGVO.
            </p>
            <h4>e. Freiwilligkeit der Bereitstellung Ihrer Daten</h4>
            <p>
              Sie sind nicht verpflichtet, Helmart's Ihre Daten bekannt zu
              geben. Wenn Sie Ihre Daten nicht bereitstellen, ist eine
              Bearbeitung Ihrer Anfrage an Helmart's allerdings nicht möglich.
            </p>
            <h3>
              2.2. Datenverarbeitung bei Nutzung der Helmart's Websites /
              Cookies
            </h3>
            <p>
              Beim Zugriff auf die von Helmart's unter den Domains{" "}
              <a href="https://www.helmarts.at/">www.helmarts.at</a> betriebene
              Website werden verschiedene Daten erfasst und verarbeitet, die
              über Ihre Nutzung der Websites Aufschluss geben. Die Erfassung
              dieser Daten erfolgt mittels sogenannter „Cookies“. Dabei handelt
              es sich um kleine Textdateien, die mit Hilfe des von Ihnen
              benutzten Web-Browsers auf Ihrem Endgerät abgelegt werden.
            </p>
            <h4>a. Verwendete Cookies und erhobene Daten</h4>
            <h5>i. Session Cookies</h5>
            <p>
              Die auf den Helmart's Websites verwendeten „Session Cookies" sind
              temporäre Dateien, die auf Ihrem Endgerät gespeichert werden, wenn
              Sie auf die jeweilige Website zugreifen, und wieder gelöscht
              werden, sobald Sie die Website wieder verlassen. Diese Cookies
              erfassen, mit welchem Endgerät und welcher Browsersoftware Sie auf
              die Helmart's Websites zugreifen.
            </p>
            <h5>ii. Google Analytics</h5>
            <p>
              Die Helmart's Websites benutzen Google Analytics, einen
              Webanalysedienst der Google LLC („Google“), Amphitheatre Parkway,
              Mountain View, CA 94043, USA. Google Analytics legt mehrere
              Cookies auf Ihrem Endgerät ab, die Ihre IP-Adresse und – davon
              ausgehend – Ihren Standort, Ihr Endgerät und das darauf
              installierte Betriebssystem, Ihre Browsersoftware, sowie die
              Anzahl, Zeiten und Dauer Ihrer Besuche auf der jeweiligen
              Helmart's Website speichert, und aufzeichnet, ob Ihr Zugriff auf
              die jeweilige Seite von einem Suchmaschinentreffer (und, wenn ja,
              ausgehend von welchem Suchbegriff), einem Weblink oder direkt (zB
              über ein „Lesezeichen“) erfolgte. Die von Google Analytics
              gesetzten Cookies werden bis zu zwei Jahre auf Ihrem Endgerät
              gespeichert.
            </p>
            <h4>b. Verarbeitungszweck und Rechtsgrundlage</h4>
            <p>
              Die Erfassung und Verarbeitung der Cookies erhobenen Daten dient
              dazu, den Webauftritt von Helmart's an die technische
              Infrastruktur, die Sie verwenden, anzupassen, und Aufschlüsse über
              die Performance ihrer Websites sowie die Interaktion ihrer Nutzer
              mit der Website zu erhalten, um das eigene Webangebot
              weiterentwickeln und laufend an die Nutzerbedürfnisse anpassen zu
              können.
            </p>
            <p>
              Rechtsgrundlage für die Verarbeitung Ihrer Daten zu den
              angeführten Zwecken ist Ihre - durch Ihre Browsereinstellungen,
              mit denen Sie das Setzen von Cookies akzeptieren, ausgedrückte –
              Einwilligung dazu gemäß Art 6 Abs 1 lit a DSGVO.
            </p>
            <h4>c. Speicherdauer</h4>
            <p>
              Die bei Nutzung der Helmart's Websites erhobenen Daten werden
              unmittelbar nach ihrer Erfassung anonymisiert und in einer Form
              gespeichert, die Ihre Identifizierung nicht mehr ermöglicht.
            </p>
            <h4>d. Übermittlung von Daten an Empfänger in Drittländer</h4>
            <p>
              Der Dienst Google Analytics wird von der Google LLC („Google“),
              Amphitheatre Parkway, Mountain View, CA 94043, USA betrieben, die
              für Helmart's als Auftragsverarbeiter die erhobenen Daten erfasst
              und aufbereitet. Da Google seinen Sitz in den USA hat, kommt es
              somit zu einem Transfer der erhobenen Daten in ein Drittland im
              Sinne der DSGVO.
            </p>
            <p>
              Google ist in der beim US-Handelsministerium geführten{" "}
              <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;amp;status=Active">
                EU-US-Privacy-Shield-Liste
              </a>{" "}
              eingetragen. Die Datenübermittlung in die USA an die Google LLC ist demnach aufgrund des
              Durchführungsbeschlusses (EU) 2016/1250 der Kommission vom 12.
              Juli 2016 über die Angemessenheit des vom EU-US-Privacy-Shield
              gebotenen Schutzes („Angemessenheitsbeschluss" im Sinne des Art 45
              DSGVO) zulässig.
            </p>
            <h4>e. Deaktivierung von Cookies</h4>
            <p>
              Sie haben die Möglichkeit, das Setzen von Cookies durch Google
              Analytics zu deaktivieren, indem Sie das unter dem Link{" "}
              <a href="http://tools.google.com/dlpage/gaoptout?hl=de">
                http://tools.google.com/dlpage/gaoptout?hl=de
              </a>{" "}
              verfügbare PlugIn herunterladen und installieren.
            </p>
            <p>
              Darüber hinaus können Sie das Setzen von Cookies auf Ihrem
              Endgerät durch Änderung der Einstellungen Ihrer Browsersoftware
              generell deaktivieren. Je nach verwendeter Browsersoftware erfolgt
              die Deaktivierung von Cookies auf unterschiedliche Weise. Für die
              folgenden gängigen Internetbrowser finden Sie unter den folgenden
              Links weiterführende Informationen:
            </p>
            <p>
              „Internet Explorer“:{" "}
              <a href="http://windows.microsoft.com/de-DE/windows-vista/Block-or-allow-cookies">
                http://windows.microsoft.com/de-DE/windows-vista/Block-or-allow-cookies
              </a>
              <br />
              „Chrome“:{" "}
              <a href="http://support.google.com/chrome/bin/answer.py?hl=de&amp;amp;hlrm=en&amp;amp;answer=95647">
                http://support.google.com/chrome/bin/answer.py?hl=de&amp;hlrm=en&amp;answer=95647
              </a>
              <br />
              „Firefox“{" "}
              <a href="https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen">
                https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen
              </a>
              <br />
              „Opera“:{" "}
              <a href="http://help.opera.com/Windows/10.20/de/cookies.html">
                http://help.opera.com/Windows/10.20/de/cookies.html
              </a>
              <br />
              „Safari“:{" "}
              <a href="https://support.apple.com/de-at/guide/safari/sfri11471/mac">
                https://support.apple.com/de-at/guide/safari/sfri11471/mac
              </a>
            </p>
            <p>
              Informationen zur Deaktivierung von Cookies bei anderen
              Internetbrowsern bietet die jeweilige Hilfefunktion.
            </p>
            <p>
              Bitte beachten Sie, dass bei einer generellen Deaktivierung von
              Cookies die Funktionalität der Helmart's Websites sowie auch von
              Websites anderer Anbieter eingeschränkt sein kann.
            </p>
            <h2>
              3. Widerrufsrecht betreffend die Einwilligung in die
              Datenverarbeitung
            </h2>
            <p>
              Wenn und soweit die Verarbeitung Ihrer Daten auf Ihrer
              Einwilligung beruht, kann diese von Ihnen jederzeit formlos
              widerrufen werden. Helmart's empfiehlt, einen derartigen Widerruf
              per E-Mail an <a href="mailto:office@helmarts.at">office@helmarts.at</a> zu
              übermitteln.
            </p>
            <p>
              Im Fall des Widerrufs Ihrer Einwilligung in die Datenverarbeitung
              wird Helmart's die Verarbeitung Ihrer personenbezogenen Daten
              einstellen und die Daten unverzüglich, längstens aber binnen eines
              Monats löschen, sofern und soweit einer solchen Löschung nicht
              rechtliche Verpflichtungen von Helmart's (zB gesetzlichen
              Aufbewahrungspflichten) entgegenstehen, oder eine weitere
              Aufbewahrung und Verarbeitung Ihrer Daten notwendig ist, damit
              Helmart's ihr zustehende Rechtsansprüche geltend machen, ausüben
              oder verteidigen kann.
            </p>
            <p>
              Bitte beachten Sie, dass ein allfälliger Widerruf Ihrer
              Einwilligung zur Datenverarbeitung durch Helmart's die
              Zulässigkeit und Rechtmäßigkeit der bis dahin erfolgten
              Speicherung und Verarbeitung Ihrer Daten nicht berührt.
            </p>
            <h2>4. Betroffenenrechte</h2>
            <p>
              Sie haben Helmart's gegenüber das Recht auf Auskunft über die
              Verarbeitung personenbezogener Daten, das Recht auf
              Richtigstellung und Löschung Ihrer Daten sowie gegebenenfalls das
              Recht auf Einschränkung der Datenverarbeitung. Außerdem haben Sie
              das Recht zu verlangen, dass Helmart's Ihnen jene Daten, die Sie
              selbst bereitgestellt haben, und die von Helmart's automatisiert
              verarbeitet werden, auf einem Datenträger in einem gängigen
              Dateiformat bereitstellt oder an einen von Ihnen genannten Dritten
              übermittelt (Recht auf Datenübertragbarkeit).
            </p>
            <p>
              Wenn Sie der Auffassung sind, dass die Verarbeitung Ihrer
              persönlichen Daten durch Helmart's gegen das geltende
              Datenschutzrecht verstößt, steht es Ihnen offen, Beschwerde bei
              der Datenschutzbehörde zu erheben.
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default DatenschutzPage
